<template>
    <div class="blackmask">
        <div class="aleater">
            <div class="close" @click="close"><img src="../../assets/icon/nos.svg" /></div>
            <div v-if="soonIndex == 0" class="callphone">
                <div @click="callPhone">
                    电话客服:188-8888-8888
                    <div>电话客服服务时间:周一至周五:9:00-18:00</div>
                </div>
            </div>
            <div v-if="soonIndex == 1" class="shouchang">
                <div>我的收藏</div>
                <div class="shouchang_content">
                    <ul v-if="yourShou && yourShou.length > 0">
                        <li v-for="(item, index) in yourShou" :key="index">
                            <img :src="item.image" />
                            <div class="shouchang_content_page">
                                <div>{{ item.name }}</div>
                                <div style="color: red;font-weight: bold;">￥{{ item.price }}</div>
                            </div>
                            <div @click="toFrom(item.id)">查看商品</div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="soonIndex == 2" class="dizhi">
                <div>我的地址</div>
                <div class="yourDizhi">
                    <div v-for="(item, index) in yourDizhi" :key="index">
                        <div><span>{{ item.consignee }}</span>&nbsp;{{ item.mobile }}&nbsp;<span
                                v-if="item.isDefault">默认</span>
                        </div>
                        <div>{{ item.addressDetail }}{{ item.address }}</div>
                        <img @click="changeDi(item)" src="../../assets/icon/Revise.png" />
                    </div>
                </div>
                <div class="add" @click="ishow = !ishow">新增收货地址</div>
                <div :class="ishow ? 'write show' : 'write'">
                    <div class="page">
                        <div class="page_mid" @click="ishowThree = true">
                            <span>收货地址：</span>
                            <span v-if="addressDetail">{{ addressDetail }}</span>
                            <span style="color:#aaa;cursor: pointer; background-color: white;  width: 65%; padding: 1% 2%;"
                                v-else>请选择收货地址</span>
                        </div>
                        <div class="page_mid">
                            <span>详细地址：</span><input v-model="detailedAddress" placeholder="请输入详细地址" />
                        </div>
                        <div class="page_mid">
                            <span>收货人：</span><input v-model="name" placeholder="请输入收货人姓名" />
                        </div>
                        <div class="page_mid">
                            <span>电话：</span><input v-model="phone" type="number" placeholder="请输入联系人电话" />
                        </div>
                        <div class="is_default">
                            <span>是否设置成默认地址：</span> <span :class="(isDefault ? 'isYous' : '')" @click="change"></span>
                        </div>
                        <div class="submit" @click="addAddress(true)">保存收货地址</div>
                        <div class="submit" v-if="!Object.keys(this.changeItem).length == 0" @click="addAddress(false)">
                            删除收货地址
                        </div>
                    </div>
                    <linkAge :pid="provinceId" :cid="cityId" :did="districtId" :ishowThree="ishowThree" @abc="close"
                        @sures='sures' />
                    <div class="back" @click="yeOp"><img src="../../assets/icon/nos.svg" /></div>
                </div>
            </div>
            <div v-if="soonIndex == 5" class="jishu">
                <div @click="callPhone">
                    百川技术客服:188-8888-8888
                    <div>电话客服服务时间:周一至周五:9:00-18:00</div>
                </div>
            </div>
            <div v-if="soonIndex == 7" class="shops">
                <div>购物车</div>
                <div class="shops_content">
                    <ul v-if="shops && shops.length > 0">
                        <li v-for="(item, index) in shops" :key="item.id" @click="toFrom(item.goodsId)">
                            <img :src="item.goodsPic" />
                            <div>
                                <span>{{ item.goodsName }}</span>
                                <span>{{ item.specificationText }}</span>
                                <span>￥{{ item.productPrice }}</span>
                            </div>
                            <div>
                                <div class="select_number">
                                    <div class="numbers">
                                        <img @click.stop="downNumber(index, item)" src="../../assets/icon/down.png" />
                                        <span>{{item.productNumber }}</span>
                                        <img @click.stop="addNumber(index, item)" src="../../assets/icon/up.png" />
                                    </div>
                                </div>
                            </div>
                            <span @click.stop="isChange(item)" :class="{ 'isspan': item.checked }"></span>
                        </li>
                    </ul>
                </div>
                <div class="toall">
                    <div>
                        <span><span :class="{ 'isspan': isAll }" @click="allIn"></span>全选</span>
                        <span @click="removeCartGoods">删除购物车</span>
                    </div>

                    <div>
                        <span style="margin-right: 3%;">合计：<span style="color:red;font-weight: bold;">￥{{ allPrice
                        }}</span></span>
                        <span @click="toSettle">去结算</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computeNumber } from '@/utils/utils.js'
import linkAge from './linkAge.vue'
export default {
    props: {
        soonIndex: {
            type: null || Number,
            default: null,
        },
        isOr: {
            type: null || Boolean,
            default: null,
        }
    },
    components: {
        linkAge,
    },
    data() {
        return {
            isDefault: false,// 是否默认地址
            name: '',// 收货人名
            detailedAddress: '',// 详细地址
            phone: '',// 手机号
            province: {// 省
                name: '',
                id: null
            },
            city: {// 市
                name: '',
                id: null
            },
            district: {// 区/县
                name: '',
                id: null
            },
            addressDetail: '',// 省市区拼接
            ishow: false,//打开新增地址
            ishowThree: false,//打开省市区组件
            provinceId: '',
            cityId: '',
            districtId: '',
            yourDizhi: [],//地址列表渲染
            yourShou: [],//收藏列表渲染
            shops: [],//购物车列表
            changeItem: {},//判断是修改地址还是新增地址,如果是修改就不是空对象
            isAll: false, // 购物车是否全选
        }
    },
    watch: {
        //打开新增地址执行
        ishow: {
            handler() {
                if (Object.keys(this.changeItem).length == 0) {
                    this.isDefault = false
                    this.name = ''
                    this.detailedAddress = ''
                    this.phone = ''
                    this.addressDetail = ''
                    this.province = {
                        name: '',
                        id: null
                    }
                    this.city = {
                        name: '',
                        id: null
                    }
                    this.district = {
                        name: '',
                        id: null
                    }
                } else {
                    this.changeItem.isDefault == 0 ? this.isDefault = false : this.isDefault = true
                    this.name = this.changeItem.consignee
                    this.detailedAddress = this.changeItem.address
                    this.phone = this.changeItem.mobile
                    this.addressDetail = `${this.changeItem.provinceName}${this.changeItem.cityName}${this.changeItem.districtName}`
                    this.province = {
                        name: this.changeItem.provinceName,
                        id: this.changeItem.province
                    }
                    this.city = {
                        name: this.changeItem.cityName,
                        id: this.changeItem.city
                    }
                    this.district = {
                        name: this.changeItem.districtName,
                        id: this.changeItem.district
                    }
                }
            }
        },
        //弹窗开关执行
        isOr: {
            immediate: true,
            async handler(val) {
                if (val && this.soonIndex == 2) {//请求地址列表
                    let res = await this.$api.getDiZhi()
                    if (res.code == 200) {
                        this.yourDizhi = res.data
                    }
                } else if (val && this.soonIndex == 1) {
                    this.getCollectList()//请求用户收藏列表
                } else if (val && this.soonIndex == 7) {
                    this.getShops()//请求购物车
                }
            }
        }
    },
    computed: {
        // 总价
        allPrice() {
            let price = 0;
            this.shops.forEach(item => {
                if (item.checked) {
                    let multiplication = computeNumber(item.productPrice, '*', item.productNumber).result
                    price = computeNumber(price, '+', multiplication).result
                }
            });
            return price;
        },
    },
    methods: {
        async close() {
            if (this.ishowThree) {
                this.ishowThree = false
            } else {
                this.ishowThree = false
                this.ishow = false
                this.$emit('toAlear');//关闭弹窗
            }
        },
        yeOp() {
            this.ishow = !this.ishow
            this.changeItem = {}
        },
        changeDi(item) {//修改地址
            this.changeItem = { ...item }
            this.ishow = true
        },
        callPhone() {
            window.location.href = 'tel:188-8888-8888'
        },
        // 是否默认收货地址
        change() {
            this.isDefault = !this.isDefault
        },
        // 添加收货地址or保存修改的话参数为true,删除地址为false
        async addAddress(value) {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            setTimeout(() => {
                loading.close();
            }, 2000);
            if (value) {
                const { name, province, city, district, phone, detailedAddress, isDefault } = this
                const reg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
                if (!reg.test(phone)) {
                    loading.close();
                    this.$alert("手机号格式错误！", "", {
                        showClose: false,
                        confirmButtonText: "确定",
                    });
                    return
                }

                if (name && province.name && city.name && district.name && detailedAddress) {
                    const params = {
                        consignee: name,
                        province: province.id,
                        provinceName: province.name,
                        city: city.id,
                        cityName: city.name,
                        district: district.id,
                        districtName: district.name,
                        mobile: phone,
                        address: detailedAddress,
                        isDefault: Number(isDefault)
                    }

                    let res;
                    let info = "添加";
                    if (Object.keys(this.changeItem).length == 0) {
                        res = await this.$api.addAddressApi(params)
                    } else {
                        info = "修改"
                        params.id = this.changeItem.id
                        params.addressDetail = this.addressDetail
                        res = await this.$api.ChangeDiZhi(params)
                    }
                    loading.close();
                    if (res.code === 200) {
                        this.$alert(`${info}成功！`, "", {
                            showClose: false,
                            confirmButtonText: "确定",
                            callback: (action) => {
                                this.changeItem = {}
                                this.close();
                            },
                        });
                    }
                } else {
                    loading.close();
                    this.$alert("请填写完成收货信息！", "", {
                        showClose: false,
                        confirmButtonText: "确定",
                    });
                }
            } else {//删除地址
                const params = {
                    ids: this.changeItem.id
                }
                const res = await this.$api.delDiZhi(params)
                loading.close();
                if (res.code === 200) {
                    this.$alert(`删除成功！`, "", {
                        showClose: false,
                        confirmButtonText: "确定",
                        callback: (action) => {
                            this.changeItem = {}
                            this.close();
                        },
                    });
                }
            }

        },
        // 地区三级联动组件确定返回信息
        sures(obj) {
            this.ishowThree = false
            this.province.name = obj.pname
            this.province.id = obj.pid
            this.city.name = obj.cname
            this.city.id = obj.cid
            this.district.name = obj.dname
            this.district.id = obj.did

            this.addressDetail = `${obj.pname}${obj.cname}${obj.dname}`
        },

        //请求用户收藏列表
        async getCollectList() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            const res = await this.$api.getCollectListApi({
                type: 2,
                upvoteType: 2,
            })
            loading.close()
            if (res.code === 200) {
                this.yourShou = res.data
            } else {
                this.$message.error("网络超时请重试")
            }
        },


        //请求购物车列表
        async getShops() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await this.$api.getShops()

            if (res && res.code == 200) {
                let sss = res.data.goodsList.length > 0 ? res.data.goodsList : []
                let c = []
                sss.forEach(item => {
                    item.goodsList.forEach(value => {
                        c.push(value)
                    })
                })

                this.shops = c.map(item => {
                    const productSpec = item.productSpec ? JSON.parse(item.productSpec).SpecificationsWhole : ''
                    let specificationText = ''
                    for (const key in productSpec) {
                        specificationText += productSpec[key].value + '/'
                    }
                    item.specificationText = specificationText.slice(0, -1)
                    item.checked = false
                    return item
                })
            }
            loading.close()
        },

        //点击购物车和收藏页进入详情页
        toFrom(item) {
            const openRoute = this.$router.resolve({
                name: "ArticlesDetail",
                params: {
                    goodsId: item,
                },
            });
            window.open(openRoute.href, "_blank");
        },

        //数量减减
        async downNumber(value, ins) {
            const loading = this.$loading({
                lock: true,
                text: "正在减少...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if (ins.productNumber <= 1) {
                loading.close()
                this.$message.warning("数量最少为1")
                return
            }
            let a = ins.productNumber - 1
            this.shops.forEach((item, index) => {
                if (index == value) {
                    item.productNumber = a
                }
            })
            let res = await this.$api.makeShopNumber({
                amount: a,
                cartId: ins.id,
            })
            if (res.code == 200) {
                this.$message.success("减少成功")
            } else {
                this.$message.success("减少失败")
            }
            loading.close()

        },
        //数量加加
        async addNumber(value, ins) {
            const loading = this.$loading({
                lock: true,
                text: "正在添加...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let a = ins.productNumber + 1
            this.shops.forEach((item, index) => {
                if (index == value) {
                    item.productNumber = a
                }
            })
            let res = await this.$api.makeShopNumber({
                amount: a,
                cartId: ins.id,
            })
            if (res.code == 200) {
                this.$message.success('添加成功')
            } else {
                this.$message.warning('添加失败')
            }
            loading.close()
        },

        //勾选商品
        isChange(item) {
            this.shops.forEach((val) => {
                if (val.id == item.id) {
                    val.checked = !val.checked
                }
            })

            this.isAll = this.shops.every(item => item.checked)
        },

        //全选
        allIn() {
            this.isAll = !this.isAll;
            this.shops.map(val => {
                val.checked = this.isAll;
            });
        },

        //跳转提交订单页面，把数据通过路由传输
        async toSettle() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            if (this.allPrice <= 0) {
                loading.close()
                this.$message.error('请选择商品')
                return
            }
            let ress = await this.$api.getDiZhi()
            if (ress.code == 200) {
                if (ress.data.length == 0) {
                    this.$message.error('您还没有收货地址，请先设置收货地址.')
                    loading.close()
                    return
                }
            }

            let data = {}
            this.shops.forEach(item => {
                if (item.checked) {
                    data[item.id] = item.productNumber
                }
            })
            this.$router.push({
                path: "Order",
                query: {
                    buyGoodsList: JSON.stringify(data),
                },
            });
        },

        // 删除购物车
        async removeCartGoods() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let is = this.shops.some(item => item.checked)
            if (is) {
                let data = {}
                this.shops.forEach(item => {
                    if (item.checked) {
                        data[item.id] = item.productNumber
                    }
                })
                const ids = Object.keys(data).join();
                const res = await this.$api.delShops({ ids });
                if (res && res.code == 200) {
                    this.getShops()
                    this.$message.success('删除成功')
                } else {
                    this.$message.error('删除失败请刷新重试')
                }
            } else {
                this.$message.error('您没有选中任意一样购物车')
            }
            loading.close()
        },
    }
}
</script>

<style lang="scss">
.blackmask {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 500;
    top: 0%;
    left: 0%;
}

.aleater {
    background-color: rgb(244, 245, 244);
    position: fixed;
    z-index: 600;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max(300px, 50%);
    height: max(420px, 40vw);
    border-radius: 10px;
    overflow: hidden;
    font-size: max(14px, 1vw);

    .close {
        position: absolute;
        right: 5%;
        top: 5%;
        cursor: pointer;
        width: max(18px, 1.5vw);
        height: max(18px, 1.5vw);
        z-index: 500;

        @media screen and (max-width: 600px) {
            top: 2.5%;
        }

        >img {
            width: 100%;
            height: 100%;
        }
    }

    .callphone {
        width: 80%;
        margin: 10% auto;

        >div {
            cursor: pointer;
        }
    }

    .shouchang {
        width: 100%;
        height: 100%;
        position: relative;

        .shouchang_content {
            &:empty::after {
                content: '暂无收藏...';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            >ul {
                margin-top: 3%;
                width: 100%;
                height: max(320px, 33vw);
                overflow: auto;
                @include scrollbar();

                >li {
                    width: 80%;
                    border-radius: 0.8vw;
                    height: max(80px, 10vw);
                    margin: 0% auto 2%;
                    margin-bottom: 2%;
                    padding: 1%;
                    background-color: white;
                    display: flex;
                    position: relative;

                    >img {
                        height: 100%;
                        width: max(100px, 20vw);
                        object-fit: cover;
                        border-radius: 0.8vw;
                    }

                    .shouchang_content_page {
                        margin-left: 5%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    >div:nth-child(3) {
                        position: absolute;
                        right: 2%;
                        bottom: 5%;
                        color: #439C4C;
                        border: 1px solid #439C4C;
                        padding: 0.5% 2%;
                        border-radius: 1vw;
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }
        }

        >div:nth-child(1) {
            text-align: center;
            padding-top: 4%;
        }
    }

    .dizhi {
        height: 101%;
        width: 100%;
        position: relative;
        font-size: max(14px, 1vw);

        >div:nth-child(1) {
            text-align: center;
            padding-top: 4%;
        }

        .add {
            position: absolute;
            width: 100%;
            left: 50%;
            bottom: 0%;
            transform: translateX(-50%);
            background-color: #439C4C;
            color: white;
            text-align: center;
            height: max(35px, 5vw);
            line-height: max(35px, 5vw);
            cursor: pointer;
        }

        .write {
            position: absolute;
            width: 100%;
            left: 50%;
            bottom: 0%;
            transform: translateX(-50%);
            color: white;
            background-color: #439C4C;
            border: 1px solid #fff;
            height: 0px;
            overflow: hidden;
            transition: all 0.5s;
            z-index: 600;
            font-size: max(16px, 1vw);

            .back {
                width: max(20px, 2vw);
                height: max(20px, 2vw);
                position: absolute;
                top: 5%;
                right: 5%;
                cursor: pointer;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            .page {
                width: max(270px, 50%);
                margin: auto;
                padding-top: 8%;

                >div {
                    padding: 3% 0%;

                    >span {
                        vertical-align: middle;
                    }

                    >input {
                        vertical-align: middle;
                        box-sizing: border-box;
                        padding: 1% 0%;
                    }
                }

                .page_mid {
                    display: flex;
                    align-items: center;

                    >input {
                        flex: 1;
                        font-size: max(14px, 1vw);
                        border: none;
                        outline: none;
                        padding: 2% 3%;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none !important;
                    }

                    >span:nth-child(1) {
                        display: inline-block;
                        width: max(60px, 30%);
                    }
                }

                .is_default {
                    position: relative;

                    >span {
                        vertical-align: middle;
                    }

                    >span:nth-child(2) {
                        cursor: pointer;
                        background-color: #eceaea;
                        height: max(20px, 2vw);
                        width: max(40px, 4.5vw);
                        display: inline-block;
                        border-radius: max(20px, 1vw);
                        transition: all .4s;
                        border: 1px solid rgba(0, 0, 0, .12);
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            height: 98%;
                            aspect-ratio: 1/1;
                            top: 50%;
                            right: 55%;
                            transform: translateY(-50%);
                            box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .25);
                            background-color: #fff;
                            border-radius: 50%;
                            transition: all .4s;
                        }
                    }

                    .isYous {
                        background-color: #439C4C !important;

                        &::after {
                            right: 0% !important;
                        }
                    }
                }
            }

            .submit {
                width: max(160px, 60%);
                margin: 10% auto;
                background-color: #439C4C;
                text-align: center;
                border-radius: max(20px, 3vw);
                cursor: pointer;
            }
        }

        .yourDizhi {
            width: 80%;
            margin: auto;
            padding-top: 5%;
            height: 70%;
            overflow: auto;
            @include scrollbar();

            >div {
                padding-bottom: 5%;
                position: relative;

                >div:nth-child(1) {
                    margin-bottom: 5px;
                }

                >div {
                    width: 80%;

                    >span:nth-child(2) {
                        padding: 2px 8px;
                        border-radius: 5px;
                        color: #439C4C;
                        user-select: none;
                        border: 1px solid #439C4C;
                        font-size: max(12px, 0.8vw);
                    }
                }

                span {
                    font-weight: bold;
                    font-size: max(14px, 1.2vw);
                }

                >img {
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    right: 0%;
                    transform: translate(0%, -50%);
                    cursor: pointer;
                    user-select: none;
                }
            }

            &:empty::after {
                position: absolute;
                content: '您当前还没有添加收货地址...';
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .show {
            height: 100%;
        }
    }

    .jishu {
        width: 80%;
        margin: 10% auto;

        >div {
            cursor: pointer;
        }
    }

    .shops {
        height: 100%;
        width: 100%;
        position: relative;
        font-size: max(14px, 1vw);

        >div:nth-child(1) {
            text-align: center;
            padding-top: 4%;
        }

        .shops_content {
            overflow: auto;
            @include scrollbar();
            height: max(300px, 27vw);
            margin-top: 3%;
            position: relative;

            &:empty::after {
                content: '购物车还是空的哦，快去选购吧';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
            }

            >ul {
                width: 80%;
                margin: auto;
                list-style-type: none;

                >li {
                    box-sizing: border-box;
                    cursor: pointer;
                    margin: 0%;
                    padding: 3% 0%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;

                    >img {
                        width: 20%;
                    }

                    >div {
                        margin-left: 3%;
                    }

                    >div:nth-child(3) {
                        flex: 1;

                        .select_number {
                            text-align: end;

                            img {
                                width: max(20px, 2vw);
                            }

                            span {
                                margin: 0% 3%;
                            }
                        }

                    }

                    >div:nth-child(2) {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5vw;

                        >span:nth-child(2) {
                            color: #8b8b8b;
                        }

                        >span:nth-child(3) {
                            color: red;
                            font-weight: bold;
                            font-size: max(12px, 1.2vw);
                        }
                    }

                    >span {
                        position: absolute;
                        transform: translateX(-150%);
                        height: max(15px, 1.5vw);
                        width: max(15px, 1.5vw);
                        border-radius: 50%;
                        border: 1px solid #d1d1d1;
                    }

                    .isspan {
                        background-color: #439C4C;
                    }
                }
            }
        }

        .toall {
            box-sizing: border-box;
            width: 90%;
            margin: auto;

            >div {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            >div:nth-child(1) {
                >span:nth-child(1) {
                    user-select: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;

                    >span {
                        display: inline-block;
                        height: max(15px, 1.5vw);
                        width: max(15px, 1.5vw);
                        border-radius: 50%;
                        border: 1px solid #d1d1d1;
                        cursor: pointer;
                        margin-right: 10px;
                    }

                    .isspan {
                        background-color: #439C4C;
                    }
                }

                >span:nth-child(2) {
                    cursor: pointer;
                    background-color: #439C4C;
                    color: #fff;
                    padding: 1% 0%;
                    box-sizing: border-box;
                    border-radius: max(20px, 0.5vw);
                    cursor: pointer;
                    width: max(80px, 7vw);
                    text-align: center;
                    user-select: none;
                }
            }

            >div:nth-child(2) {
                margin-top: 2%;

                >span:last-child {
                    background-color: #4C9C51;
                    color: #fff;
                    padding: 1% 0%;
                    box-sizing: border-box;
                    border-radius: max(20px, 0.5vw);
                    cursor: pointer;
                    width: max(80px, 7vw);
                    text-align: center;
                    user-select: none;
                }
            }
        }
    }
}</style>