<template>
    <page-view class="props-make" :up-class="'props-make'">
        <div class="wrapper">
            <div class="wrapper_container_1">
                <div>
                    <div class="tab_bar_item" @click="toOrder(0)">
                        <img src="@/assets/icon/all.png" />
                        <span>全部</span>
                        <span class="icons" :style="{ left: `${move}%` }"></span>
                    </div>
                    <div class="tab_bar_item" @click="toOrder(1)">
                        <img src="@/assets/icon/Tobepaid.png" />
                        <span>待支付</span>
                    </div>
                    <div class="tab_bar_item" @click="toOrder(2)">
                        <img src="@/assets/icon/pendingreceipt.png" />
                        <span>待发货</span>
                    </div>
                    <div class="tab_bar_item" @click="toOrder(5)">
                        <img src="@/assets/icon/returnthegoods.png" />
                        <span>待收货</span>
                    </div>
                    <div class="tab_bar_item" @click="toOrder(3)">
                        <img src="@/assets/icon/goodorbad.png" />
                        <span>待评价</span>
                    </div>
                </div>
                <ul>
                    <li class="order_item" v-for="(item) in orderList" :key="item.id">
                        <div class="order_header">
                            <span class="goods_name one_line">{{ item.companyName }}</span>
                            <span class="order_number">订单号:{{ item.orderNo }}</span>
                        </div>
                        <div class="order_content" @click="toDetails(item)">
                            <div class="goods_content" v-for="goods in item.goodsList" :key="goods.pId">
                                <img :src="goods.goodsPic" />
                                <div class="goods_info">
                                    <span class="title">{{ goods.name }}</span>
                                    <span class="specification" v-if="goods.lhy">{{ goods.lhy }}</span>
                                    <div class="price_count">
                                        <span class="price">¥{{ goods.price }}</span>
                                        <span class="count">x {{ goods.numberWait }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="order_status">
                            <!-- <span class="btn_2" v-if="(item.state == 4 || item.state === 5 || item.state === 6) && item.state2 === 1">申请退款</span> -->
                            <span class="status" v-if="item.state === 9">订单已完成</span>
                            <span class="status" v-if="item.state === 10">订单已取消</span>
                            <span class="status" v-if="item.state === 11">订单已过期</span>
                            <span class="status btn_2" v-if="(item.state === 8) && (item.commented === 0)" @click="toGoodOrBad(item)">立即评价</span>
                            <span class="status"
                                v-if="(item.state === 4 || item.state === 5 || item.state === 6) && item.state2 === 1">订单待发货</span>
                            <!-- 待收货 -->
                            <div class="btn_container" v-if="item.state === 7">
                                <div class="btn_2" @click="cancelOrder(8, item)">确认收货</div>
                            </div>
                            <div class="btn_container" v-if="item.state2 === 1">
                                <div class="btn_2" @click="lookWhere(item)">查看物流</div>
                            </div>
                            <!-- 未付款 -->
                            <div class="btn_container" v-if="item.state2 == 0 && item.state === 4">
                                <div class="btn_1" @click="cancelOrder(10, item)">取消订单</div>
                                <div class="btn_2" @click="continuePay(item)">继续支付</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div :class="this.widths > 600 ? 'wrapper_container_2 pc_wrapper' : 'wrapper_container_2'">
                <div class="tab_bar_item" @click="redirectTab2(7)">
                    <img src="@/assets/icon/shop.png" />
                    <span>购物车<span class="shopsnum" v-if="this.shopNums && this.shopNums.length != 0">{{
                        this.shopNums.length }}</span></span>
                </div>
                <div class="tab_bar_item" @click="redirectTab2(1)">
                    <img src="@/assets/icon/collect.png" />
                    <span>我的收藏</span>
                </div>
                <div class="tab_bar_item" @click="redirectTab2(2)">
                    <img src="@/assets/icon/Shippingaddress.png" />
                    <span>收货地址</span>
                </div>
                <!-- <div class="tab_bar_item" @click="redirectTab2(0)">
                    <img src="@/assets/icon/customerservice.png" />
                    <span>联系客服</span>
                </div> -->
            </div>
        </div>
        <div class="out" @click="out">退出登录</div>
        <footer-com></footer-com>
        <Aleater @toAlear="toAlear" v-show="isOr" :isOr="isOr" :soonIndex="soonIndex" />
    </page-view>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Aleater from './aleater.vue'

export default {
    name: "MySelf",
    components: {
        Aleater,
    },
    data() {
        return {
            tabIndex: 0,
            isOr: false,//控制弹窗开关
            soonIndex: null,
            orderList: [],//订单列表
            shopNums: []
        }
    },
    created() {
        this.askSho()
        this.toOrder(this.tabIndex);
    },
    watch: {
        $route(newRoute, oldRoute) {
            window.location.reload()
        },
    },
    computed: {
        ...mapState(['infoObj', 'screenWidth']),
        widths() {
            return this.screenWidth
        },
        move() {
            if (this.tabIndex == 5) {
                return 50 + 3 * 100
            } else if (this.tabIndex == 3) {
                return 50 + 4 * 100
            } else {
                return 50 + this.tabIndex * 100
            }
        }
    },
    methods: {
        ...mapActions(["setInfoObj", "setNavIndex"]),
        lookWhere(item){
            this.$router.push({
                name:'lookWhere',
                query:{
                    id:JSON.stringify(item.id),
                    billCode:JSON.stringify(item.logisticCode),//查物流用
                    orderNo:JSON.stringify(item.orderNo2),//查物流用
                    orderNumber:JSON.stringify(item.orderNo)//查信息用
                }
            })
        },
        //打开弹窗
        redirectTab2(index) {
            this.soonIndex = index
            this.toAlear()
        },

        //关闭弹窗
        toAlear() {
            this.isOr = !this.isOr
        },

        //按条件渲染列表
        async toOrder(index) {
            const loading = this.$loading({
                lock: true,
                text: "正在加载订单列表，请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.tabIndex = index

            const res = await this.$api.getOrderListApi({
                orderType: index
            });
            loading.close()
            if (res && res.code == 200) {
                this.orderList = res.data
                this.allGoodsSpecification(this.orderList)
            } else {
                this.$message.error('获取订单失败，请刷新重试.')
            }
        },

        // 点击订单详情页
        toDetails(item) {
            const openRoute = this.$router.resolve({
                name: "InfoPage",
                query: {
                    id: item.id,
                    orderNumber: item.orderNo
                },
            });
            window.open(openRoute.href, "_blank");
        },

        //点击继续支付，跳转pc二维码页面支付
        continuePay(item) {
            let skipData = {// 支付所需参数
                // productId: item.id,//商品id
                id: item.id,//订单id
                // orderNo: item.orderNo,//采购订单号（CG开头）
                summaryOrderNo: item.orderNo2,//销售订单号，与立即支付是不同的
                // totalFee: item.totalMoney//总价
            };

            this.$router.push({
                path: "GiveMoney",
                replace: false,
                query: { info: JSON.stringify(skipData) },
            });
        },

        // 取消订单/确定收货
        async cancelOrder(state, item) {
            const loading = this.$loading({
                lock: true,
                text: "正在加载订单列表，请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            const res = await this.$api.changeOrderStateApi({
                state,
                orderNo: item.orderNo,
            });
            loading.close()
            if (res && res.code == 200) {
                this.$message.success('操作成功')
            } else {
                this.$message.error('操作失败')
            }

            this.toOrder(this.tabIndex);//刷新当前订单当前列表
        },

        //立即评价
        toGoodOrBad(item) {
            let data = {
                id: item.id,
                orderNumber: item.orderNo
            };

            this.$router.push({
                path: "GoodOrBad",
                query: { goodInfo: JSON.stringify(data) },
            });
        },

        // 处理后端没传好的数据
        allGoodsSpecification(values) {
            values && values.forEach((item) => {
                item.goodsList && item.goodsList.forEach((god) => {
                    if (god.SpecificationsSimple) {
                        let a = JSON.parse(god.specificationValues)
                        god.lhy = a.SpecificationsSimple
                    }
                })
            });
        },

        //退出登录
        out() {
            this.setInfoObj({
                account: '',
                id: null,
                status: null,
                sex: null,
                phone: '',
                companyid: null,
                name:''
            })
            window.localStorage.removeItem("token")
            this.$router.push({
                name: 'ShareGoods',
            });
            this.setNavIndex("j");
        },

        //查询购物车数量
        async askSho() {
            let res = await this.$api.getShops()

            if (res && res.code == 200) {
                let sss = res.data.goodsList.length > 0 ? res.data.goodsList : []
                sss && sss.forEach(item => {
                    item.goodsList && item.goodsList.forEach(value => {
                        this.shopNums.push(value)
                    })
                })
            }
        }
    }
}
</script>

<style lang="scss">
.props-make {
    .wrapper {
        width: 60%;
        margin: auto;
        border-radius: 10px;
        font-size: max(14px, 0.8vw);

        >div {
            margin-top: 5%;
            border-radius: 10px;
            transition: all 0.3s;

            &:hover {
                box-shadow: 0px 0px 5px 1px #39d849;
            }
        }

        .wrapper_container_1 {
            background-color: rgb(231, 231, 229);

            >div:nth-child(1) {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 2% 0%;
                background-color: rgb(231, 231, 229);
                border-radius: 10px;

                >div {
                    position: relative;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    >img {
                        width: 20%;
                        padding-bottom: 4%;
                    }

                    .icons {
                        position: absolute;
                        bottom: -15%;
                        left: 50%;
                        height: 3px;
                        width: 50%;
                        transform: translateX(-50%);
                        background-color: #4C9C51;
                        transition: all 0.3s;
                    }
                }
            }

            >ul {
                width: 96%;
                margin: 1% auto;
                height: max(350px, 40vw);
                overflow: auto;
                @include scrollbar();

                .order_item {
                    width: 100%;
                    padding: 1%;
                    background-color: #fff;
                    border-radius: 0.8vw;
                    margin-bottom: 1%;
                    box-sizing: border-box;

                    .order_header {
                        padding-bottom: 1%;
                        border-bottom: 2px solid #eee;
                        display: flex;
                        justify-content: space-between;

                        .goods_name {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .order_number {
                            color: #807a78;
                            white-space: nowrap;
                        }

                    }

                    .order_content {
                        cursor: pointer;

                        .goods_content {
                            width: 100%;
                            margin-top: 2%;
                            display: flex;

                            >img {
                                width: max(50px, 8vw);
                                border-radius: 1vw;
                                margin-right: 5%;
                            }

                            .goods_info {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                gap: 8%;

                                .specification {
                                    color: #bbb;
                                }

                                .price_count {
                                    display: flex;
                                    justify-content: space-between;

                                    .price {
                                        color: #f00;
                                    }

                                    .count {
                                        color: #807d7c;
                                    }
                                }
                            }
                        }
                    }

                    .order_status {
                        width: 100%;
                        margin-top: 3%;
                        display: flex;
                        gap: 0.5%;
                        justify-content: flex-end;
                        align-items: center;

                        .btn_2 {
                            padding: 5px 10px;
                            box-sizing: border-box;
                            border-radius: 20px;
                            cursor: pointer;
                            white-space: nowrap;
                            color: #fff;
                            background-color: #439C4C;
                        }

                        .btn_container {
                            display: flex;
                            align-items: center;
                            gap: 2%;

                            .btn_1,
                            .btn_2 {
                                padding: 5px 10px;
                                box-sizing: border-box;
                                border-radius: 20px;
                                cursor: pointer;
                                white-space: nowrap;
                            }

                            .btn_1 {
                                color: #000;
                                border: 2px solid #aaa;
                            }

                            .btn_2 {
                                color: #fff;
                                background-color: #439C4C;
                            }
                        }

                        .status {
                            padding-left: 1%;
                        }
                    }
                }
            }
        }

        .wrapper_container_2 {
            display: flex;
            justify-content: center;
            padding: 2% 0%;
            background-color: rgb(231, 231, 229);

            &:hover {
                box-shadow: 0px 0px 5px 1px #39d849;
            }

            >div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;
                flex: 1;

                .shopsnum {
                    position: absolute;
                    top: 0%;
                    left: 10%;
                    background-color: red;
                    color: white;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 0.6vw;
                    transform: translateY(0%) scale(0.7);

                    @media screen and (max-width: 500px) {
                        transform: translateY(-50%) scale(0.6);
                        font-size: 14px;
                    }
                }

                >img {
                    width: 20%;
                    padding-bottom: 4%;
                }
            }
        }

        .pc_wrapper {
            display: block;
            padding: 1% 0%;
            width: 7vw;
            position: fixed;
            right: 1%;
            top: max(100px, 6vw);
            box-sizing: border-box;
            transform: translateX(60%);
            transition: all .8s ease;
            &:hover{
                transform: translateX(0%);
            }
            >div {
                padding: 10% 0%;
                white-space: nowrap;
            }
        }
    }

    .out {
        width: max(160px, 30vw);
        margin: 5% auto;
        padding: 1% 0%;
        color: white;
        background-color: #439C4C;
        text-align: center;
        border-radius: max(20px, 3vw);
        cursor: pointer;
        font-size: max(14px, 1vw);
    }

    @media screen and (max-width:600px) {
        .wrapper {
            width: 95%;
        }
    }
}
</style>