<template>
    <div v-show='ishowThree'>
        <div class="linkage-shade" @click="btnClick(0)"></div>
        <div class="linkage">
            <div class="linkage-choose">
                <span class="linkage-btn" @click="btnClick(0)">取消</span>
                <span class="linkage-btn" @click="btnClick(1)">确定</span>
            </div>
            <div class="linkage-select">
                <div class="select-itemsss province">
                    <div v-for="(item, index) in provinceList" class="select-list-item"
                        :class="{ active: province == item.id }" @click="chooseArea(2, item.id, item.name)" :key="index">
                        {{ item.name }}</div>
                </div>
                <div class="select-itemsss city">
                    <div v-for="(item, index) in cityList" class="select-list-item" :class="{ active: city == item.id }"
                        @click="chooseArea(3, item.id, item.name)" :key="index">{{ item.name }}</div>
                </div>
                <div class="select-itemsss district">
                    <div v-for="(item, index) in districtList" class="select-list-item"
                        :class="{ active: district == item.id }" @click="chooseArea(4, item.id, item.name)" :key="index">
                        {{ item.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    watch: {
        province(val) {
            this.getAreaData(3, val);
        },
        city(val) {
            this.getAreaData(4, val);
        },
        ishowThree() {
            this.getAreaData(2, 1);
        }
    },
    props: {
        ishowThree: {
            type: Boolean,
            default: false
        },
        pid: [String, Number],
        cid: [String, Number],
        did: [String, Number],
    },
    data() {
        return {
            provinceList: [],
            cityList: [],
            districtList: [],
            province: this.pid,
            provinceName: '',
            city: this.cid,
            cityName: '',
            district: this.did,
            districtName: '',
        }
    },
    methods: {
        btnClick(type) {//确认信息提交拼接地址给父组件
            switch (type) {
                case 0:
                    this.$emit('abc')
                    break;
                case 1:
                    this.$emit('sures', {
                        pid: this.province,
                        pname: this.provinceName,
                        cid: this.city,
                        cname: this.cityName,
                        did: this.district,
                        dname: this.districtName
                    });
                    break;
            }
        },
        chooseArea(type, id, name) {
            switch (type) {
                case 2:
                    this.province = id;
                    this.provinceName = name;
                    break;
                case 3:
                    this.city = id;
                    this.cityName = name;
                    break;
                case 4:
                    this.district = id;
                    this.districtName = name;
                    break;
            }
        },
        async getAreaData(type, pid) {
            let datas = await this.$api.getSelectAddressApi({
                parent_id: pid || 1,
                type: type
            });
            if (datas.data && datas.data.length > 0) {
                switch (type) {
                    case 2:
                        this.provinceList = datas.data;
                        let provinceFilter = this.provinceList.filter(pro => pro.id == this.province);
                        if (provinceFilter.length > 0) {
                            this.province = provinceFilter[0].id;
                            this.provinceName = provinceFilter[0].name;
                        } else {
                            this.province = this.provinceList[0].id;
                            this.provinceName = this.provinceList[0].name;
                        }
                        break;
                    case 3:
                        this.cityList = datas.data;
                        let cityFilter = this.cityList.filter(city => city.id == this.city);
                        if (cityFilter.length > 0) {
                            this.city = cityFilter[0].id;
                            this.cityName = cityFilter[0].name;
                        } else {
                            this.city = this.cityList[0].id;
                            this.cityName = this.cityList[0].name;
                        }
                        break;
                    case 4:
                        this.districtList = datas.data;
                        let districtFilter = this.districtList.filter(dist => dist.id == this.district);
                        if (districtFilter.length > 0) {
                            this.district = districtFilter[0].id;
                            this.districtName = districtFilter[0].name;
                        } else {
                            this.district = this.districtList[0].id;
                            this.districtName = this.districtList[0].name;
                        }
                        break;
                }
            } else {
                this.$message.error("请检查网络或者是否登录过期!");
            }
        }
    },
}
</script>
  
<style lang="scss">
.linkage-shade {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.linkage {
    width: 100%;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    height: 60%;
    background: #fff;
    z-index: 20;
    color: black;
    font-size: max(14px,1vw);

    .linkage-select {
        display: flex;
        justify-content: space-between;
        padding: 0% 3%;

        .select-itemsss {
            width: 32%;
            height: 35vh;
            overflow: auto;
            @include scrollbar();

            .select-list-item {
                overflow: hidden;
                // span-overflow: ellipsis;
                cursor: pointer;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.active {
                    color: red;
                }
            }
        }
    }

    .linkage-choose {
        display: flex;
        justify-content: space-between;
        background: #fff;
        border-bottom: 1px solid #eaeaea;
        padding: 0% 3%;

        .linkage-btn {
            cursor: pointer;
            padding: 2% 0%;

            &.sure {
                color: red;
            }
        }
    }
}




// .linkage-select {
//     margin: 20upx;
//     display: flex;
//     justify-content: space-between;
// }

// .select-itemsss {
//     width: 32%;
//     overflow: auto;
// }

// .select-list-item {
//     overflow: hidden;
//     // span-overflow: ellipsis;
//     text-overflow: ellipsis;
//     white-space: nowrap;

//     &.active {
//         color: red;
//     }
// }

// .linkage-choose {
//     display: flex;
//     justify-content: space-between;
//     background: #fff;
//     border-bottom: 1px solid #eaeaea;
//     padding: 0% 3%;
//     .linkage-btn {
//         cursor: pointer;
//         padding: 2% 0%;
//         &.sure {
//             color: red;
//         }
//     }
// }
</style>
  